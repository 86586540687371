import React, { useEffect, useState } from 'react';
import { BrowserBarcodeReader, BarcodeFormat, DecodeHintType } from "@zxing/library";
import "./App.css"

const closeIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" xmlSpace="preserve">
    <path d="M50,0C22.4,0,0,22.4,0,50c0,27.6,22.4,50,50,50s50-22.4,50-50C100,22.4,77.6,0,50,0z M73.1,65.4l-7.7,7.7L50,57.7L34.6,73.1  l-7.7-7.7L42.3,50L26.9,34.6l7.7-7.7L50,42.3l15.4-15.4l7.7,7.7L57.7,50L73.1,65.4z"/>
  </svg>
);

const App = () => {
  const [codeReader, setCodeReader] = useState(null);

  useEffect(() => {
    if (!codeReader)
      setCodeReader(new BrowserBarcodeReader());
  }, [codeReader])
  
  useEffect(() => {
    if (!codeReader)
      return;

          codeReader.hints = codeReader.hints || new Map();
          codeReader.hints.set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.EAN_13]);

            codeReader.decodeOnceFromConstraints({
              audio: false,
              video: {
                facingMode: { exact: "environment" },
                width: {
                  min: 480,
                  ideal: 960
                },
                height: {
                  min: 480,
                  ideal: 960
                }
              }
            }, 'video')
            .then(result => {
              window.location.replace(`https://porcpays.com/rechercher?t=${result.getText()}`)
            })
            .catch(e => {
              if (e && e.message && e.message === 'Permission denied') {
                alert('Vous devez autoriser l’usage de votre caméra puis recharger la page.');
              } else {
                console.error(e);
              }
            });
      }, [codeReader]);

  return (
    <div>
      <button data-product-scanner="true" data-target="barcodeInput">Scan Barcode</button>
      <div className="product-scanner-container">
        <video id="video"></video>
        <div className="actions d-flex">
          <div className="action-stop flex-fill d-flex justify-content-center align-items-center" onClick={() => {
            window.location.replace(`https://porcpays.com/`)
            // codeReader.stopAsyncDecode();
            // codeReader.reset();
          }}>
            {closeIcon}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
